<template>
  <router-view/>
</template>

<style lang="scss">

  *{
    padding: 0;
    margin: 0;
  }

  //清除 滚动条
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

</style>

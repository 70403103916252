import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/about"),
  },
  {
    path: "/introduction",
    name: "introduction",
    component: () => import("@/views/introduction"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

// //解决路由地址重复的报错问题(一劳永逸)
// const originalPush = router.prototype.push
// router.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

export default router;
